import { Callout } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { MapDataCard } from "../../components/MapDataCard/MapDataCard";
import type { Tables } from "../../database.types";
import { supabase } from "../../supabase";
import "./style.css";
import MapPlot from "../../components/Maps/MapPlot";

const datasetQuery = (id: string) => supabase.from("datasets_with_user_new").select("*").eq("id", id);

export function MapView({ plot = false }): JSX.Element {
  const { datasetID } = useParams();
  const [error, setError] = useState<string>();
  const [dataset, setDataset] = useState<Tables<"datasets_with_user">>();

  useEffect(() => {
    let loading = true;
    if (!datasetID) {
      return () => {
        loading = false;
      };
    }
    datasetQuery(datasetID).then(({ data, error }) => {
      if (loading && data !== null) {
        const datasetResult = data[0];
        if (!datasetResult) {
          setError("Project does not exist.");
          loading = false;
          return;
        }
        setDataset(datasetResult);
      }
      if (error) {
        setError("Error fetching projet.");
      }
    });
    return () => {
      setDataset(undefined);
      loading = false;
    };
  }, [datasetID]);

  return (
    <div className="map-view">
      {!!error && (
        <Callout.Root color="red" className="map-error">
          <Callout.Icon>
            <RiErrorWarningLine />
          </Callout.Icon>
          <Callout.Text>{error}</Callout.Text>
        </Callout.Root>
      )}
      {dataset && !plot && <MapDataCard dataset={dataset} />}
      {dataset && plot && <MapPlot dataset={dataset} />}
    </div>
  );
}
