import * as Sentry from "@sentry/react";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { SessionWrapper } from "./components/SessionWrapper";
import { supabase } from "./supabase";

import "./index.css";
import { Helper } from "./components/Helper";

export function FallbackComponent({ errorText }: { errorText?: string }) {
  if (errorText) console.error(errorText);
  return <Helper showIcon={true} text="An error has occurred" type="error" />;
}

const myFallback = <FallbackComponent />;

export const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={myFallback}>
      <SessionContextProvider supabaseClient={supabase}>
        <SessionWrapper />
      </SessionContextProvider>
    </Sentry.ErrorBoundary>
  );
};
