import { Flex, Spinner, TabNav } from "@radix-ui/themes";
import { useSessionContext } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { FallbackComponent } from "../../App";
import { Sidebar } from "../../components/Sidebar";
import { TopicsProvider } from "../../components/TopicsContext/TopicsContext";
import { AuthSignIn } from "../../screens/AuthSignIn";
import { supabase } from "../../supabase";
import "./index.css";
import { Navbar } from "../Navbar";

const datasetQuery = (id: string) => supabase.from("datasets").select("is_public").eq("id", id);

export const SessionWrapper = () => {
  const { pathname } = useLocation();
  const { datasetID } = useParams();
  const { isLoading, session, error } = useSessionContext();
  const [passwordResetScreen, showPasswordResetScreen] = useState<boolean>(false);
  const [mapLoading, setMapLoading] = useState(true);
  const [isPublicMap, setIsPublicMap] = useState(false);


  const atExplore = pathname.startsWith('/explore')

  if(session) {
    window.heap.identify(session.user.email);
    console.log("Welcome to Bunka Explore, ", session.user.email, '!');
  }

  useEffect(() => {
    let loading = true;


    if (pathname.startsWith("/map") && datasetID) {
      datasetQuery(datasetID).then((data) => {
        if (loading && !data?.error && data?.data.length) {
          const datasetResult = data.data[0];
          if (!datasetResult) {
            loading = false;
            return;
          }

          setIsPublicMap(datasetResult.is_public ?? false);
          setMapLoading(false);
          loading = false;
          return;
        }
      });
    } else {
      loading = false;
      setMapLoading(false);
      setIsPublicMap(false);
    }
    return () => {
      loading = false;
      setMapLoading(false);
      setIsPublicMap(false);
    };
  }, [pathname, datasetID]);

  supabase.auth.onAuthStateChange(async (event, _) => {
    if (event === "PASSWORD_RECOVERY") {
      // show screen to update user's password
      showPasswordResetScreen(true);
    }
    if (event === "USER_UPDATED") {
      // show screen to update user's password
      showPasswordResetScreen(false);
    }
  });



  return (
    <TopicsProvider>
      <div className="app-screen">
        <Navbar session={!!session} />
        <div className="app-content">
          {atExplore && <Outlet />}
          {!atExplore && (mapLoading || isLoading) && !error && <Spinner loading={true} />}
          {!atExplore && !mapLoading && (session || isPublicMap) && !isLoading && !error && <Outlet />}
          {!atExplore && !mapLoading && !isLoading && !isPublicMap && 
            (pathname === "/projects" || pathname === "/user") && 
            (!session || passwordResetScreen) && (
            <AuthSignIn passwordResetScreen={passwordResetScreen} redirectTo="/projects" />
          )}
          {!atExplore && !mapLoading && !isLoading && !isPublicMap && 
            (pathname !== "/projects" && pathname !== "/user") && 
            !session && (
            <Outlet />
          )}
          {!atExplore && error && <FallbackComponent errorText={error.message} />}
        </div>
      </div>
    </TopicsProvider>
  );
};
