import { Badge, Button, Card } from "@radix-ui/themes";
import { Link, useParams } from "react-router-dom";
import mapPreview from "/static/map-preview.png";
import type { Tables } from "../../database.types";
import "./style.css";
import MapPlot from "../../components/Maps/MapPlot";
import { supabase } from "../../supabase";
import { useEffect, useState } from "react";
import { RiArrowDownLine } from "react-icons/ri";
import DataTable from "../../components/DataTable/DataTable";
import { getSampleDatasetSource } from "../../components/TopicsContext/apiClient";
import { useSession } from "@supabase/auth-helpers-react";

const datasetQuery = (id: string) => supabase.from("datasets_with_user_new").select("*").eq("id", id);

export const Dataset = (): JSX.Element => {
  const { id: datasetID } = useParams<{ id?: string }>();
  const [error, setError] = useState<string>('')
  const [dataset, setDataset] = useState<Tables<"datasets_with_user">>();

  useEffect(() => {
    let loading = true;
    if (!datasetID) {
      return () => {
        loading = false;
      };
    }
    datasetQuery(datasetID).then(({ data, error }) => {
      if (loading && data !== null) {
        const datasetResult = data[0];
        if (!datasetResult) {
          setError("Project does not exist.");
          loading = false;
          return;
        }
        setDataset(datasetResult);
      }
      if (error) {
        setError("Error fetching project.");
      }
    });
    return () => {
      setDataset(undefined);
      loading = false;
    };
  }, [datasetID]);


  return (
    <div
      className="dataset-card"
      style={{
      }}
    >
      {dataset ? (
        <DatasetInside dataset={dataset} showTitle={true} />
      ) : (
        <div>No dataset available</div>
      )}
    </div>
  );
};

interface DatasetInsideProps {
  dataset: Partial<Tables<"datasets_with_user">>;
  showTitle: boolean;
}

const scrollToTop = () => {
  const buttonElement = document.getElementById("more-details-button");
  if (buttonElement) {
    buttonElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

const DatasetInside = ({ showTitle, dataset }: DatasetInsideProps): JSX.Element => {
  const session = useSession();
  const [loading, setLoading] = useState<boolean>(true);
  const [sample, setSample] = useState<Record<string, string>[]>([]);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    // Check if the window is scrollable
    if (document.body.scrollHeight > window.innerHeight) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    let ignore = false;
    async function getSample(dataset: Partial<Tables<"datasets_with_user">>) {
      setLoading(true);

      if (!dataset.id || dataset.is_public === null) {
        return;
      }
      try {
        const data = await getSampleDatasetSource(dataset.id, session);
        if (!ignore) {
          if (data) {
            setSample(data);
          }
        }
      } catch (exc) {
        console.error(exc);
      } finally {
        setLoading(false);
      }
    }

    getSample(dataset!);

    return () => {
      ignore = true;
    };
  }, [dataset]);

  const formatDate = (dateString: string | undefined): string => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust options as needed for formatting
  };

  return (
    <div className="dataset-screen">
      {/* {showPreview && <img className="map-preview" alt="Map" src={mapPreview} />} */}
      <div className="map">
        {dataset ? <MapPlot dataset={dataset as Required<Partial<Tables<"datasets_with_user">>>} /> : null}
      </div>
      <div className="details">
        <div className="bottom-bar">
          <div className="header">
            <h1>{dataset.name}</h1>
            <Button size={'4'} id="more-details-button" onClick={scrollToTop}>
              {isScrolled ? 'Less Details' : 'More Details'} <RiArrowDownLine color="white" />
            </Button>
          </div>
          <div className="description">
            {dataset.description}
          </div>
          <div className="created-by">
            {"Created on "+  formatDate(dataset.created_at)}
            {', by '+dataset.username}
          </div>
          <div className="table-container">
            <h3>Raw dataset</h3>
            <div className="table">
              <DataTable
                dataset={dataset as Required<Partial<Tables<"datasets_with_user">>>}
                loading={loading}
                sample={sample}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
