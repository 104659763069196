import { Card, Link, TabNav } from "@radix-ui/themes"
import './style.css'
import { Logo } from "../Logo"
import { AvatarIcon } from "./avatar"


export const Navbar = ({ session }: { session: boolean }) => {
    return (
        <div className="navbar-container">
            <div className="bunka-logo">
                <Link href="/">
                <Logo />
                </Link>
            </div>
            <div className="nav-tabs">
                <TabNav.Root size="2">
                    <TabNav.Link href="/explore" active={location.pathname === "/explore" || location.pathname == '/'}>
                        <div>
                            Explore
                        </div>
                    </TabNav.Link>
                    <TabNav.Link href="/datasets" active={location.pathname.includes("/datasets")}>
                        <div>
                            Datasets
                        </div>
                    </TabNav.Link>
                    {session && (
                        <TabNav.Link href="/create" active={location.pathname === "/create"}>
                            <div>
                                Create
                            </div>
                        </TabNav.Link>
                    )}
                </TabNav.Root>
            </div>
            <div className="avatar-icon">
                <Link href="/user">
                    <AvatarIcon  />
                </Link>
            </div>
        </div >
    )
}